import { PARAMETER_CODENAME } from "./annotation.const";

export const baseUrls = {
  DATA_RESOURCE: '/data-resource/data-resource/',
  INVENTORY: '/inventory/sites',
  MAP_BOX: 'https://api.mapbox.com/geocoding/v5/mapbox.places/',
  FORCAST: 'https://api.open-meteo.com/v1/forecast?latitude',
};

export const assetStatusMapping = {
  NOT_STARTED: 'not started',
  IN_PROGRESS: 'in progress',
  COMPLETED: 'completed',
};

export const DATA_TYPES_TABS = [
  { label: 'Visual', value: 'visual' },
  { label: 'Thermal', value: 'thermal' },
  { label: 'Video', value: 'video' },
  { label: 'Ortho', value: 'ortho' },
];

export const FINDINGS_GROUP = {
  ASSET: 'Asset',
  COMPONENT: 'Component',
  FINDINGS: 'Findings',
  PRIORITY: 'Priority',
};

export const GROUP_BY_OPTIONS = ['Asset', 'Findings', 'Priority'];

export const LISTING_MODES = {
  ASSETS: 'Assets',
  FINDINGS: 'Findings',
  GALLERY: 'Gallery',
  ANNOTATIONS: 'Annotations',
};

export const FINDINGS_MESSAGE = {
  ATTACHMENT_DUPLICATE_DATA: 'Duplicate Data',
  FINDINGS_SAVE_SUCCESS: 'Findings saved successfully',
  FINDINGS_UPDATED_SUCCESS: 'Findings updated successfully',
  FINDINGS_SAVE_ERROR: 'Failed to save findings',
  FINDINGS_GET_ERROR: 'Failed to get finding detail',
  FINDINGS_GET_DROPDOWN_ERROR: 'Failed to get dropdown data',
  FINDINGS_ATTACHMENT_UPLOAD_ERROR: 'Failed to upload',
  FINDINGS_ATTACHMENT_DUPLICATE_DIALOG_TITLE: 'Duplicate file(s)',
  FINDINGS_ATTACHMENT_DUPLICATE_DIALOG_MESSAGE:
    'Some files that are uploaded already exist. Do you want to replace or reuse the existing files??',
  FINDINGS_ATTACHMENT_DUPLICATE_DIALOG_REPLACE:
    'Yes, I wish to replace the existing file(s).',
  FINDINGS_ATTACHMENT_DUPLICATE_DIALOG_REUSE:
    'Yes, I wish to reuse the existing file(s).',
  FINDINGS_ATTACHMENT_CONFIRM_REPLACE_DIALOG_TITLE: 'Replace Confirmation',
  FINDINGS_ATTACHMENT_CONFIRM_REPLACE_DIALOG_MESSAGE:
    'Existing file(s) get deleted, and then try uploading files. Are you sure you want to delete an existing file?.',
  FINDINGS_ATTACHMENT_CONFIRM_REPLACE_DIALOG_CONFIRM:
    'Yes, delete the existing file(s).',
  FINDINGS_ATTACHMENT_CONFIRM_REPLACE_DIALOG_CANCLE: 'Cancel',
  FINDINGS_ATTACHMENT_CONFIRM_REUSE_DIALOG_TITLE: 'Reuse Confirmation',
  FINDINGS_ATTACHMENT_CONFIRM_REUSE_DIALOG_MESSAGE:
    'Are you sure you want to reuse an existing file?',
  FINDINGS_ATTACHMENT_CONFIRM_REUSE_DIALOG_CONFIRM:
    'Yes, reuse the existing file(s).',
  FINDINGS_ATTACHMENT_CONFIRM_REUSE_DIALOG_CANCLE: 'Cancel',
  FINDINGS_DELETE_SUCCESS: 'Findings deleted successfully',
  DEL_CONFIRM_CONTENT: 'Are you sure you want to delete this?',
  DEL_CONFIRM_TITLE: 'Delete Confirmation',
  DEL_CONFIRM_DEL_BTN: 'Delete',
  DEL_CONFIRM_CANCEL_BTN: 'Cancel',
};

export const ANNOTATION_TYPES = {
  VISUAL: 'visual',
  THERMAL: 'thermal',
  VIDEO: 'video',
  ORTHO: 'ortho',
};
export const IMAGE_TYPES = {
  IMAGE_THERMAL: 'imageThermal',
  IMAGE_RGB: 'imageRGB',
} as const;

export const ORTHO_TYPE = 'orthoRGBTiles';

export const DEFAULT_THERMAL_EXIFDATA = {
  emissivity: 1,
  objectDistance: 5,
  reflectedTemp: 25,
  atmTemp: 25,
  relativeHumidity: 50,
  irWindowTemp: 20,
  irWindowTransmission: 1,
  planckR1: 16358.414,
  planckR2: 0.014547601,
  planckO: 0,
  planckB: 2500,
  planckF: 1,
  atmTransAlpha1: 0.00656899996101856,
  atmTransAlpha2: 0.0126200001686811,
  atmTransBeta1: -0.00227600010111928,
  atmTransBeta2: -0.00667000003159046,
  atmTransBetaX: 1.89999997615814,
};

export const THERMAL_ANNOTATION = {
  // Annotation deafults
  DEFAULT_ANNOTATION_WIDTH: 100,
  DEFAULT_ANNOTATION_HEIGHT: 50,
  DEFAULT_ANNOTATION_STROKE_COLOR: '#00F0FF',
  DEFAULT_ANNOTATION_STROKE: 2,
  DEFAULT_ANNOTATION_FONT_SIZE: 10,
  DEFAULT_ANNOTATION_FONT_COLOR: 'white',
  DEFAULT_ANNOTATION_FONT_BG_COLOR: '#00C0CC',
  DEFAULT_ANNOTATION_FONT_BG_HEIGHT: 20,
  DEFAULT_ANNOTATION_FONT_BG_RADIUS: 5,
  DEFAULT_ANNOTATION_RECT_POSITION: { x: 0, y: 0 },
  DEFAULT_ANNOTATION_TEXT_POSITION_Y: 25,
  DEFAULT_ANNOTATION_TEXT_BG_POSITION_X: 0,
  DEFAULT_ANNOTATION_TEXT_BG_POSITION_Y: 30,
  // Reference point defaults
  DEFAULT_REFERENCE_POINT_HORZLINE_POINTS: [-10, 0, 10, 0],
  DEFAULT_REFERENCE_POINT_HORZLINE_STROKE: 2,
  DEFAULT_REFERENCE_POINT_HORZLINE_STROKE_COLOR: 'black',
  DEFAULT_REFERENCE_POINT_HORZOUTLINE_POINTS: [-10, 0, 10, 0],
  DEFAULT_REFERENCE_POINT_HORZOUTLINE_STROKE: 4,
  DEFAULT_REFERENCE_POINT_HORZOUTLINE_STROKE_COLOR: 'white',
  DEFAULT_REFERENCE_POINT_VERZLINE_POINTS: [0, -10, 0, 10],
  DEFAULT_REFERENCE_POINT_VERZLINE_STROKE: 2,
  DEFAULT_REFERENCE_POINT_VERZLINE_STROKE_COLOR: 'black',
  DEFAULT_REFERENCE_POINT_VERZOUTLINE_POINTS: [0, -10, 0, 10],
  DEFAULT_REFERENCE_POINT_VERZOUTLINE_STROKE: 4,
  DEFAULT_REFERENCE_POINT_VERZOUTLINE_STROKE_COLOR: 'white',
  DEFAULT_REFERENCE_POINT_CENTERSQ_POSITION: { x: -2, y: -1 },
  DEFAULT_REFERENCE_POINT_CENTERSQ_WIDTH: 4,
  DEFAULT_REFERENCE_POINT_CENTERSQ_HEIGHT: 2,
  DEFAULT_REFERENCE_POINT_CENTERSQ_COLOR: 'black',
  DEFAULT_REFERENCE_POINT_LABELTEXT_POSITION: { x: 10, y: -25 },
  DEFAULT_REFERENCE_POINT_LABELTEXT_FONT_SIZE: 10,
  DEFAULT_REFERENCE_POINT_LABELTEXT_FONT_COLOR: 'white',
  DEFAULT_REFERENCE_POINT_LABELTEXTBG_POSITION: { x: 5, y: -30 },
  DEFAULT_REFERENCE_POINT_LABELTEXTBG_HEIGHT: 20,
  DEFAULT_REFERENCE_POINT_LABELTEXTBG_OPACITY: 0.3,
  DEFAULT_REFERENCE_POINT_LABELTEXTBG_RADIUS: 5,
  DEFAULT_REFERENCE_POINT_LABELTEXTBG_COLOR: 'black',
  DEFAULT_REFERENCE_POINT_TEMPTEXT_POSITION: { x: 10, y: 6 },
  DEFAULT_REFERENCE_POINT_TEMPTEXT_FONT_SIZE: 8,
  DEFAULT_REFERENCE_POINT_TEMPTEXT_FONT_COLOR: 'white',
  DEFAULT_REFERENCE_POINT_TEMPTEXTBG_POSITION: { x: 5, y: 4 },
  DEFAULT_REFERENCE_POINT_TEMPTEXTBG_HEIGHT: 12,
  DEFAULT_REFERENCE_POINT_TEMPTEXTBG_OPACITY: 0.5,
  DEFAULT_REFERENCE_POINT_TEMPTEXTBG_RADIUS: 5,
  DEFAULT_REFERENCE_POINT_TEMPTEXTBG_COLOR: 'black',
  // Thumbnail
  THUMBNAIL_SCROLL_AMOUNT: 200,
  THERMAL_IMAGE_PAGE_LIMIT: 200,
  // Temperature
  TEMPERATURE_CONVERTION_F_MUL_CONST: 1.8,
  TEMPERATURE_CONVERTION_F_SUM_CONST: 32,
  TEMPERATURE_CONVERTION_K_SUM_CONST: 273.15,
};

export const COLOR_SCHEME = {
  GREY: 'Grey',
  RAINBOW: 'Rainbow',
  RAINBOW_HC: 'RainbowHC',
  LAVA: 'Lava',
  ARTIC: 'Artic',
  IRON: 'Iron',
} as const;

export const THERMAL_TAB_TYPES = {
  PARAMETER: 'parameter',
  FINDINGS: 'findings',
};

export const GALLERY_LAYOUT_PROPERTY = {
  MIN_X_RELATIVE: 410,
  MAX_X_RELATIVE: 1050,
  DEFAULT_WIDTH: 350,
  RESIZE_DRAG_POSITION: 350,
  DEFAULT_FULL_WIDTH: '700px',
  SLIDER_DRAG_CLASSNAME: 'image-layout-slide-dragger',
  SLIDER_DEFAULT_STATE: '2px solid #FFFFFF',
  SLIDER_ACTIVE_STATE: '2px solid #FF5252',
  FLEX_DEFAULT_GROW: '0',
  MOVE_CURSOR_POINT: 'ew-resize',
  MOUSE_DOWN_CURSOR_POINT: 'grab',
  MOUSE_UP_CURSOR_POINT: 'grabbing',
};

export const VIDEO_ANNOTATION = {
  THUMBNAIL_SCROLL_AMOUNT: 200,
};

export const VIDEO_TYPES = {
  RESOURCE_TYPE: 'videoRGB',
} as const;

export const LABEL_LOOKUP_TYPE = {
  MEASUREMENT: 'Measurement',
};
export const DIRECTION = {
  NE: 'NE',
  SE: 'SE',
  SW: 'SW',
  NW: 'NW',
  N: 'N',
  E: 'E',
  W: 'W',
  S: 'S',
};

export const NOTIFY_MSG = {
  ERROR_CLASS: 'toaster-error',
  SUCCESS_CLASS: 'toaster-success',
  MESSAGE: "You don't have permission to view Ortho Map Data",
  NO_DATA_MESSAGE: 'This Mission has no ortho data',
  FETCHING_ERROR: 'An error occurred while fetching data.',
  NO_ANNOTATION: 'This finding has no annotation image',
  DELETE_FINDING: 'All findings deleted successfully',
  DELETE_ERROR: 'Error occurred while deleting findings:',
  UPDATE_FINDING: 'Findings updated successfully:',
  UPDATE_ERROR: 'Error occurred while saving:',
  FINDINGS_EMPTY: 'Select at least one Finding',
  DELETE_ANNOTATION: 'Annotation deleted successfully',
  FINDINGS_WARNING: 'Select Only one Finding',
  DELETE_MARKER: 'Marker deleted successfully',
};

export const DIALOG_BOX_MESSAGES = {
  MARKER: 'MARKER',
  ANNOTATION: 'ANNOTATION',
};

export const NO_RECORD_FOUND = 'No record found';

export const VERSION_REQUIRED = 'Version Name is required';

export const ANNOTATION_BUTTONS = {
  START_2D_ANNOTATION: 'Start 2D Annotation',
  AMS_PUBLISH: 'AMS Publish',
};

export const TIMEOUT_DURATION_MS = 500;

export const ADD_FINDING_TOOLTIPS = {
  FINDING_NAME: "Enter finding Name",
  ASSETS: "Select assets",
  COMPONENTS: "Select components",
  FINDING_TYPE: "Select finding type",
  SEVERITY_LEVEL: "Select severity level",
  REMARKS: "Add your remarks",
  ATTACHMENTS: "Add attachments"
};

export const FINDING_TYPE_REQUIRED = "Select finding type first";

export const ANNOTATION_NAME_ERROR_MESSAGE = "Annotation name cannot be empty";

export const NEW_BOX_OFFSET_VALUE = 50;

export const MISSING_MISSION_NAME = "N/A";

export const TIME_DURATION_MS = 10;

export const THERMAL_PARAMS = [
  {
    name: 'Emissivity',
    codeName: PARAMETER_CODENAME.EMISSIVITY,
    value: '0.00',
    type: '',
  },
  {
    name: 'Refl.temp.',
    codeName: PARAMETER_CODENAME.REFLECTED_TEMP,
    value: '0.0',
    type: '°C',
  },
  {
    name: 'Distance',
    codeName: PARAMETER_CODENAME.OBJECT_DISTANCE,
    value: '0.0',
    type: 'ft',
  },
  {
    name: 'Atmospheric temp.',
    codeName: PARAMETER_CODENAME.ATMTEMP,
    value: '0.0',
    type: '°C',
  },
  {
    name: 'Relative Humidity',
    codeName: PARAMETER_CODENAME.RELATIVE_HUMIDITY,
    value: '0.0',
    type: '%',
  },
]

export const FINDING_MISS_MATCH_MESSAGE = (mode: string) =>
  `This Finding does not belong to the ${mode} mode.`;