import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'highlight'
})
export class HighlightPipe implements PipeTransform {

  transform(text: string, search: string): string {
    if (!search || !text || text.length < 2) {
      return text;
    }
    
    const regex = new RegExp(search, 'gi');
    const highlighted = text.replace(regex, match => `<span class="highlight">${match}</span>`);

    return highlighted;
  }
}