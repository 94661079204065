import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { fromEvent, take } from 'rxjs';
import * as bootstrap from 'bootstrap';
import { EventTypes } from '../../../interfaces/components/notification.interface';
import { HIDDEN_BS_TOAST_EVENT } from '../../../constants/notification.constant';

@Component({
  selector: 'app-custom-notification',
  templateUrl: './custom-notification.component.html',
  styleUrls: ['./custom-notification.component.scss'],
})
export class CustomNotificationComponent {
  @Output() disposeEvent = new EventEmitter();
  @ViewChild('toastElement', { static: true })
  toastEl!: ElementRef;
  @Input() type!: EventTypes;
  @Input() title!: any;
  @Input() message!: string;
  toast!: any;

  eventTypes = EventTypes;

  constructor() {}

  ngOnInit() {
    this.showToast();
  }

  showToast() {
    this.toast = new bootstrap.Toast(
      this.toastEl.nativeElement,
      this.type === EventTypes.sticky
        ? { autohide: false, delay: 3000 }
        : { delay: 3000 },
    );
    fromEvent(this.toastEl.nativeElement, HIDDEN_BS_TOAST_EVENT)
      .pipe(take(1))
      .subscribe(() => this.hideToast());
    this.toast.show();
  }

  hideToast() {
    this.toast.dispose();
    this.disposeEvent.emit();
  }
}
