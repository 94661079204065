import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  OnDestroy,
} from '@angular/core';

import { Subscription } from 'rxjs';
import { ToastEvent } from '../../../interfaces/components/notification.interface';
import { NotificationService } from '../../../services/notification.service';

@Component({
  selector: 'app-toaster-notify',
  templateUrl: './toaster-notify.component.html',
  styleUrls: ['./toaster-notify.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToasterNotifyComponent implements OnInit, OnDestroy {
  private toastSubscription$: Subscription;
  currentToasts: ToastEvent[] = [];
  constructor(
    private notificationService: NotificationService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.subscribeToToasts();
  }

  subscribeToToasts() {
    this.toastSubscription$ = this.notificationService.toastEvents.subscribe(
      (toast) => {
        const currentToast: ToastEvent = {
          type: toast.type,
          title: toast.title,
          message: toast.message,
        };
        this.currentToasts.push(currentToast);
        this.changeDetectorRef.detectChanges();
      },
    );
  }

  dispose(index: number) {
    this.currentToasts.splice(index, 1);
    this.changeDetectorRef.detectChanges();
  }

  ngOnDestroy(): void {
    if (this.toastSubscription$) {
      this.toastSubscription$.unsubscribe();
    }
  }
}
