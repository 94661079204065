import { HttpClient, HttpParams } from '@angular/common/http';
import { lastValueFrom, Observable } from 'rxjs';
import { IGetAssetsParams, IInventoryAPIResponse, IInventoryRecord } from 'projects/hierarchy-structure/src/app/interfaces/api-response/inventory-api';
import { INVENTORY_API_ROUTES } from 'projects/hierarchy-structure/src/app/constants/routes';

interface IInventoryApi {
  getAssets: (params: IGetAssetsParams) => Promise<IInventoryAPIResponse> | null;
  getAssetDetail: (id: any) => Observable<IInventoryRecord>;
}

export class InventoryAPI implements IInventoryApi {
  constructor(private http: HttpClient) {}

  getAssets(params: IGetAssetsParams) {
    try {
      const url = INVENTORY_API_ROUTES.ASSETS;
      const httpParams = new HttpParams({ fromObject: {...params} });
      return lastValueFrom(
        this.http.get<IInventoryAPIResponse>(url, { params: httpParams })
      );
    } catch (error) {
      console.error('Error getting inventory assets: ', error);
      return null;
    }
  }

  getAssetDetail(id: string) {
    const url = `${INVENTORY_API_ROUTES.ASSETS}/${id}`;
    return this.http.get<IInventoryRecord>(url);
  }
}
