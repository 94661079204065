import { USER_ROLE_PERMISSIONS } from "projects/digital-twin/src/app/constants";
import {
  ISeverityLabel,
  IHeadingItems,
  INewInspectionLabelsData,
} from '../interfaces/label-management.interface';

export const REMARKS_CHAR_LIMIT = 200;

export const HEADING_ITEMS: IHeadingItems = [
  'S. No',
  'Label Type',
  'Component Type',
  'Finding Label',
  'Severity',
  'Remarks',
  'Action',
];

export const HEADING_ITEMS_GENERAL: IHeadingItems = [
  'S. No',
  'Label Type',
  'Label',
  'Action',
];

export const SEVERITY_HEADING_ITEMS_GENERAL: IHeadingItems = [
  'S. No',
  'Label Type',
  'Label',
  'Color',
  'Action',
];

// styling Class Name
export const SEVERITY_LABEL: ISeverityLabel = {
  GOOD: 'good',
  MONITOR: 'monitor',
  URGENT: 'urgent',
  CRITICAL: 'critical',
};

export const TABLE_DATA = {
  NUM_ITEMS_PER_PAGE: 20,
  HEADING_ITEMS: HEADING_ITEMS,
  HEADING_ITEMS_GENERAL: HEADING_ITEMS_GENERAL,
  SEVERITY_LABEL: SEVERITY_LABEL,
  WINDOW_SIZE: 3,
  WINDOW_SIZE_END: 4,
  FETCH_LIMIT: 200,
};

export const TEMPLATE_DATA = {
  TITLE: 'Add New Template',
  data: [
    {
      formControlName: 'name',
      label: 'Template Name',
      placeholder: 'Enter name',
      dropdown: false,
      required: true,
    },
    {
      formControlName: 'industry',
      label: 'Industry',
      placeholder: 'Select industry',
      dropdown: true,
      dropdownValues: ['Grid', 'Telco'],
      required: true,
    },
    {
      formControlName: 'findingType',
      label: 'Type of Findings',
      placeholder: 'Select Type of Findings',
      dropdown: true,
      dropdownValues: ['General', 'Defect'],
      required: true,
    },
    {
      formControlName: 'tags',
      label: 'Selection',
      placeholder: 'Enter Selection',
      dropdown: false,
      required: true,
    },
    {
      formControlName: 'severity',
      label: 'Add Severity',
      placeholder: 'Enter Severity',
      tags: true,
      dropdownValue: [],
      dropdown: true,
      required: true,
    },
  ],
};

export const LABEL_MANAGEMENT_NOTIFY_MESSAGES = {
  NEW_TEMPLATE_CREATED: 'New template created successfully',
  NEW_TEMPLATE_CREATE_FAILED: 'New template creation failed',
  NEW_LABEL_INSPECTION_CREATED: 'New label inspection created successfully',
  NEW_LABEL_INSPECTION_CREATE_FAILED: 'New label inspection creation failed',
  DELETE_LABEL_SUCCESS: 'Finding Label deleted successfully',
  DELETE_LABEL_FAILURE: 'Finding Label deletion failed',
  DELETE_GENERAL_LABEL_SUCCESS: 'Label deleted successfully',
  DELETE_GENERAL_LABEL_FAILURE: 'Label deletion failed',
  LABEL_INSPECTION_UPDATED: 'Label updated successfully',
  LABEL_INSPECTION_UPDATE_FAILED: 'Label update failed',
  COMPONENT_LABEL_CREATED: 'Component label created successfully',
  SEVERITY_LABEL_CREATED: 'Severity label created successfully',
  COMPONENT_LABEL_DELETED: 'Component label deleted successfully',
  SEVERITY_LABEL_DELETED: 'Severity label deleted successfully',
  SEVERITY_COLOR_UPDATE_FAILURE: 'Severity color update failed',
  SEVERITY_COLOR_UPDATE_SUCCESS: 'Severity color updated successfully',
};

export const LABEL_TYPES = {
  COMPONENT: 'Component Type',
  SEVERITY: 'Severity',
  MEASUREMENT: 'Measurement',
};

export const LABEL_TYPES_API = {
  COMPONENT: 'Component',
  SEVERITY: 'Severity',
  MEASUREMENT: 'Measurement',
};

export const ADD_NEW_TEMPLATE = 'Add New Template';

export const FORM_CONTROL_NAMES = {
  COMPONENT: 'component type',
  SEVERITY: 'severity',
  FINDING: 'finding label',
  REMARKS: 'remarks',
  TYPES: 'label type',
};

export const FIELD_INPUT_TYPES = {
  TEXT: 'text',
  DROPDOWN: 'dropdown', // for single select
  MULTIPLE: 'multiple', // for multiple select
  DESCRIPTION: 'description',
};

export const LABEL_MANAGEMENT_TABS = {
  INSPECTION_FINDING: 'Inspection Finding',
  MEASUREMENT: 'Measurement',
  COMPONENT: 'Component',
  SEVERITY: 'Severity',
};

  export const PERMISSION_MAP: { [x:string] : string[] | null } = {
  [LABEL_MANAGEMENT_TABS.MEASUREMENT] : [USER_ROLE_PERMISSIONS.CANVAS_MEASUREMENT_EDIT, USER_ROLE_PERMISSIONS.CANVAS_MEASUREMENT_DELETE, USER_ROLE_PERMISSIONS.CANVAS_MEASUREMENT_CREATE],
  [LABEL_MANAGEMENT_TABS.INSPECTION_FINDING] : null,
  [LABEL_MANAGEMENT_TABS.COMPONENT] : null,
  [LABEL_MANAGEMENT_TABS.SEVERITY] : null
}

export const FINDING_LABEL_TYPES = ['General', 'Defect'];

export const NEW_INSPECTION_LABELS_DATA: INewInspectionLabelsData[] = [
  {
    formControlName: FORM_CONTROL_NAMES.TYPES,
    label: 'Label Type',
    placeholder: 'Enter Label Type',
    type: FIELD_INPUT_TYPES.DROPDOWN,
    dropdownValues: FINDING_LABEL_TYPES,
    required: true,
    allowCreation: false,
  },
  {
    formControlName: FORM_CONTROL_NAMES.COMPONENT,
    label: 'Component Type',
    placeholder: 'Enter Component Type',
    type: FIELD_INPUT_TYPES.DROPDOWN,
    dropdownValues: [],
    required: false,
    tags: true,
    allowCreation: true,
  },
  {
    formControlName: FORM_CONTROL_NAMES.FINDING,
    label: 'Finding Label',
    placeholder: 'Enter Finding Label',
    type: FIELD_INPUT_TYPES.TEXT,
    required: true,
    tags: true,
    allowCreation: false,
  },
  {
    formControlName: FORM_CONTROL_NAMES.SEVERITY,
    label: 'Severity',
    placeholder: 'Enter Severity',
    type: FIELD_INPUT_TYPES.MULTIPLE,
    dropdownValues: [],
    required: false,
    tags: true,
    allowCreation: true,
  },
  {
    formControlName: FORM_CONTROL_NAMES.REMARKS,
    label: 'Remarks',
    placeholder: 'Enter Remarks',
    type: FIELD_INPUT_TYPES.DESCRIPTION,
    required: false,
    allowCreation: false,
  },
];

export const LABEL_MANAGEMENT_TABLE_DELETE = {
  TITLE: 'Delete Confirmation',
  MESSAGE: 'Are you sure you want to delete this finding?',
  CONFIRM_BTN_TEXT: 'Delete',
  CANCEL_BTN_TEXT: 'Cancel',
};

export const LABEL_ITEM_DELETE = {
  TITLE: 'Delete Confirmation',
  MESSAGE: 'Are you sure you want to delete this item?',
  CONFIRM_BTN_TEXT: 'Delete',
  CANCEL_BTN_TEXT: 'Cancel',
};

export const SEVERITY_LABEL_COLORS = [
  '#FF4646',
  '#66D6A6',
  '#FFA346',
  '#3C4AF2',
  '#A88A25',
  '#9646EF',
  '#FECACA',
  '#8EA7EC',
  '#D69FED',
  '#E79090',
  '#3A8961',
  '#17394C',
  '#9E630B',
  '#703737',
  '#413DC7',
  '#718557',
];

export const INSPECTION_LABEL_NAME = {
  COMPONENT_LABEL: 'componentLabel',
  SEVERITY_LABEL: 'severityLabels'
}