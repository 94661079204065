<div class="nested-accordion-container">
  <ng-container *ngTemplateOutlet="Recursion; context: { entries: accordionData, level: 1 }" />
</div>
<ng-template
  #Recursion
  let-entries="entries",
  let-level="level">
  @for(entry of entries; track entry._id) {
    <div class="nested-accordion-entry"
      [id]="'entry-' +  entry._id"
      (click)="handleSelection(entry)"
      [ngStyle]="{ 'padding-left': (level * 10) + 'px' }">
      @if(entry.loading) {
        <div class="spinner-container">
          <div class="spinner">
            <span class="spinner-border spinner-border-sm"></span>
          </div>
        </div>
      } @else if (isAssetNode(entry) === false) {   
        <img
        class="arrow-down"
        [ngClass]="{ 'arrow-right': !entry.expanded }"
        [src]="SVG_ICONS.ARROW_RIGHT | assetUrl"
        alt="arrow-right"/>
      }
      <span class="entry-text" [ngClass]="{ 'final-entry': isAssetNode(entry) }" [innerHTML]="entry.text | highlight:searchTerm">
      </span>
    </div>
    <div class="line-break"></div>
    @if(entry.children?.length && entry.expanded) {
      <ng-container *ngTemplateOutlet="Recursion; context: { 
        entries: entry.children,
        level: level + 1 }" />
    }
  }
</ng-template>