export const ASSET_OVERVIEW_PANEL_NAVIGATION_LABELS = {
  CANVAS: 'Canvas',
  REPORT: 'Report',
};

export const ASSET_OVERVIEW_PANEL_TAB_LABELS = {
  ANALYTICS: 'Analytics',
  INFORMATION: 'Information',
};

export const ASSET_OVERVIEW_PANEL_TABS = [
  ASSET_OVERVIEW_PANEL_TAB_LABELS.ANALYTICS,
  ASSET_OVERVIEW_PANEL_TAB_LABELS.INFORMATION,
];

export const ROUTES_FOR_MISSION = {
  getCanvasRoute: (missionId: string) => `/2d-annotation/${missionId}/visual`,
  getReportRoute: (missionId: string, assetId: string) => `/dashboard/findings?assetId=${assetId}&missionId=${missionId}`,
}

export const OVERVIEW_PANEL_REPORT_CONFIG = {
  showDashboard: true,
  visiableComponents: {
    findingsTotal: true,
    findingByPriority: true,
    findingsByComponent: true,
    findingType: true
  },
  customStyle: {
    componentStyles: {
      top: '0px',
      width: '380px',
      background: 'transparent',
      backdropFilter: 'unset'
    },
    findingsTypeStyles: {
      height: '300px'
    }
  },
}

export const MISSION_CREATION_DATE_FORMAT = 'dd/MM/yyyy'

export const PLACEHOLDER_FOR_DETAILS = "NA"

export const TIME_ZONE_LOCALE_EN_GB = 'en-GB'
