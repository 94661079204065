import { HttpClient } from '@angular/common/http';
import { API_PAGE_LIMIT } from 'projects/hierarchy-structure/src/app/constants/hierarchy.constant';
import { MISSIONS_API_ROUTES } from 'projects/hierarchy-structure/src/app/constants/routes';
import { lastValueFrom } from 'rxjs';

interface MissionsAPIMethodsType {
  getMissions: (params: { assetIds?: string[] }) => Promise<any> | null;
}

export class MissionsAPI implements MissionsAPIMethodsType {
  constructor(private http: HttpClient) {}

  getMissions(params: { assetIds?: string[] }) {
    try {
      let queryParams = '';
      queryParams += `pageLimit=${API_PAGE_LIMIT}&`;

      Object.keys(params).forEach((paramKey) => {
        let value: string | string[] =
          params[paramKey as keyof typeof params] ?? '';
        if (typeof value === 'object' && Array.isArray(value)) {
          value = encodeURIComponent(value.join(','));
        }
        queryParams += `${paramKey}=${value}&`;
      });
      const url = `${MISSIONS_API_ROUTES.DATA_PROC_ONLY}?${queryParams}`;
      return lastValueFrom(this.http.get<any>(url));
    } catch (error) {
      console.error('Error getting missions: ', error);
      return null;
    }
  }
}
