import { HttpClient } from '@angular/common/http';
import { HIERARCHY_STRUCTURE_API_ROUTES } from 'projects/hierarchy-structure/src/app/constants/routes';
import { IHierarchyAPIResponse } from 'projects/hierarchy-structure/src/app/interfaces/api-response/hierarchy-api';
import { lastValueFrom } from 'rxjs';

interface HierarchyApiMethodsType {
  getListHierarchy: () => Promise<IHierarchyAPIResponse[]> | null;
}

export class HierarchyAPI implements HierarchyApiMethodsType {
  constructor(private http: HttpClient) {}

  getListHierarchy(): Promise<IHierarchyAPIResponse[]> | null {
    try {
      const url = `${HIERARCHY_STRUCTURE_API_ROUTES.HIERARCHY_STRUCTURE}?tree=true`;
      return lastValueFrom(this.http.get<IHierarchyAPIResponse[]>(url));
    } catch (error) {
      console.error('Error fetching hierarchy: ', error);
      return null;
    }
  }
}
