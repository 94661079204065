import { Injectable } from '@angular/core';
import { IInventoryRecord } from '../../../interfaces/api-response/inventory-api';
import { IMissionRecord } from '../../../interfaces/api-response/misison-api';
import { ISelectedNode } from '../../../interfaces/components/nested-accordion';
import { SELECTED_NODE_INTIAL_VALUES } from '../../../constants/components/asset-navigation';
import { SignalsStoreService } from './signals-store-service';
import { STORE_DATA_KEY } from '../../../constants/store.constant';

@Injectable({
  providedIn: 'root',
})
export class AssetSignalStore extends SignalsStoreService<{
  [STORE_DATA_KEY.ASSETS]: IInventoryRecord[];
  [STORE_DATA_KEY.SELECTED_NODE]: ISelectedNode;
  [STORE_DATA_KEY.SELECTED_ASSET_MISSION_LIST]: IMissionRecord[];
}> {
  constructor() {
    super();

    // set initialValues
    this.setState({
      [STORE_DATA_KEY.ASSETS]: [],
      [STORE_DATA_KEY.SELECTED_NODE]: SELECTED_NODE_INTIAL_VALUES,
      [STORE_DATA_KEY.SELECTED_ASSET_MISSION_LIST]: [],
    });
  }
}
