import { Injectable } from '@angular/core';
import { ApiService } from '../shared/shared-services/api/api.service';
import { API_PAGE_LIMIT } from '../constants/hierarchy.constant';
import { Subject } from 'rxjs';
import { AssetSignalStore } from '../shared/shared-services/store/asset-signal-store.service';
import {
  HIERARCHY_LAST_NODE_PLACEHOLDER,
  INVENTORY_TYPE,
} from '../constants/components/asset-navigation';
import {
  HierarchyNodeType,
  IHierarchyTree,
  NodeResultType,
} from '../interfaces/components/hierarchy-analytics';
import { IHierarchyAPIResponse } from '../interfaces/api-response/hierarchy-api';
import { IGetAssetsParams, IInventoryRecord } from '../interfaces/api-response/inventory-api';
import { isObject, isString } from 'lodash';
import { NotificationService } from './notification.service';
import { EventTypes } from '../interfaces/components/notification.interface';
import { API_MESSAGE_EVENTS } from '../constants/api-messages.constant';
import { HttpErrorResponse } from '@angular/common/http';
import { INVENTORY } from '../constants/routes';
import { STORE_DATA_KEY } from '../constants/store.constant';

@Injectable({
  providedIn: 'root',
})
export class HierarchyAnalyticsService {
  constructor(
    private apiService: ApiService,
    private assetSignalStore: AssetSignalStore,
    private notificationService: NotificationService
  ) {}

  hierarchy: IHierarchyTree = {}; // Store for hierarchy data
  private assetListForHierarchy = new Subject<string[]>();
  hierarchyNodeAssets = this.assetListForHierarchy.asObservable();
  loadingAssets = new Subject<boolean>();

  // Method to save hierarchy data
  saveHierarchy(hierarchyList: IHierarchyAPIResponse[]) {
    hierarchyList?.forEach((item: IHierarchyAPIResponse) => {
      this.hierarchy[item?._id] = this.formTree(item);
    });
  }

  // Recursively form tree structure from hierarchy data
  formTree(hierarchyItem: IHierarchyAPIResponse) {
    const hierarchyNode: IHierarchyTree = {};
    if (hierarchyItem?.children?.length) {
      hierarchyItem?.children?.forEach((item: IHierarchyAPIResponse) => {
        hierarchyNode[item._id] = this.formTree(item);
      });
    } else {
      return HIERARCHY_LAST_NODE_PLACEHOLDER;
    }
    return hierarchyNode;
  }

  // Method to fetch assets from API based on hierarchy node ID
  async getAssetApi(hierarchyId: string, nextCursor?: string): Promise<string[]> {
    const params: IGetAssetsParams = {
      hierarchyId,
      pageLimit: API_PAGE_LIMIT,
      showSummary: true
    };
    if (nextCursor) {
      params.nextCursor = nextCursor
    }
    let assetData;
    try {
      assetData = await this.apiService.get(INVENTORY).getAssets(params);
    } catch (error) {
      const errorObj = error as HttpErrorResponse;
      const errorMessage = errorObj?.error?.message;
      this.notificationService.showToast({
        type: EventTypes.error,
        message: Array.isArray(errorMessage) && errorMessage?.length ? errorMessage[0] : API_MESSAGE_EVENTS.GENERIC_ERROR_MESSAGE,
      });
      return []; // Return empty array if an error occurs
    }
    
    const assetIds: string[] = assetData?.records?.map((asset: IInventoryRecord) => asset._id) ?? [];
    
    // If nextCursor is present and there are more assets to fetch, recursively call assetApi
    if (assetData?.meta?.nextCursor) {
      const nextPageAssets = await this.getAssetApi(hierarchyId, assetData.meta.nextCursor);
      return assetIds.concat(nextPageAssets); // Concatenate current page assets with assets from next page
    }
    
    return assetIds; // Return asset IDs when all assets are fetched
  }
  

  // Method to get assets from hierarchy data
  async getAssetByHierarchy(hierarchyNode: HierarchyNodeType, hierarchyId: string) {
    if (
      isString(hierarchyNode) &&
      hierarchyNode === HIERARCHY_LAST_NODE_PLACEHOLDER
    ) {
      return this.getAssetApi(hierarchyId);
    } else if (Array.isArray(hierarchyNode)) {
      return hierarchyNode;
    } else if (isObject(hierarchyNode)) {
      const promise: Promise<HierarchyNodeType>[] = [];
      for (const key in hierarchyNode) {
        promise.push(this.getAssetByHierarchy(hierarchyNode[key], key));
      }
      const hierarchyList = await Promise.all(promise);
      hierarchyList.forEach((hierarchy, i) => {
        hierarchyNode[Object.keys(hierarchyNode)[i]] = hierarchy;
      });
      return hierarchyNode;
    }
    return [];
  }

  // Method to retrieve node from hierarchy by ID
  getHierarchyNodeById(tree: HierarchyNodeType, id: string): NodeResultType {
    if (isString(tree)) {
      return false;
    }
    if (Array.isArray(tree)) {
      return false;
    }
    if (isObject(tree) && tree.hasOwnProperty(id)) {
      return tree[id];
    }
    if (isObject(tree)) {
      for (const key in tree) {
        const node: NodeResultType = this.getHierarchyNodeById(tree[key], id);
        if (node) {
          return node;
        }
      }
    }
    return false;
  }

  // Method to handle selection of a hierarchy node
  hierarchySelect(hierarchyId: string) {
    const hierarchyNode = this.getHierarchyNodeById(this.hierarchy, hierarchyId);
    if (hierarchyNode) {
      this.loadingAssets.next(true);
      this.getAssetByHierarchy(hierarchyNode, hierarchyId)
        .then((res) => {
          const assetList = this.getAssetList(res);
          // GETTING Selected Node From Store
          const selectedNode = this.assetSignalStore.select(STORE_DATA_KEY.SELECTED_NODE)()
          if (
            selectedNode?.type === INVENTORY_TYPE.HIERARCHY &&
            selectedNode.id === hierarchyId
          ) {
            this.assetListForHierarchy.next(assetList); // Emit asset list for selected node
          }
          this.loadingAssets.next(false);
        })
        .catch(() => {
          this.loadingAssets.next(false);
        });
    }
  }

  // Helper method to convert hierarchy node to array of assets
  getAssetList = (hierarchyNode: HierarchyNodeType) => {
    let assetList: string[] = [];
    if (isString(hierarchyNode)) {
      return [];
    }
    if (Array.isArray(hierarchyNode)) {
      return hierarchyNode;
    }
    if (isObject(hierarchyNode)) {
      for (const key in hierarchyNode) {
        assetList = assetList.concat(this.getAssetList(hierarchyNode[key]));
      }
    }
    return assetList;
  };

  // Method to set asset list IDs
  setAssetListIds(assetIds: string[]) {
    this.assetListForHierarchy.next(assetIds);
  }
}
