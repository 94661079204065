import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MISSION_CREATION_DATE_FORMAT } from '../../../constants/overview-panel.constant';

@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  styleUrl: './timeline.component.scss',
})
export class TimelineComponent {
  @Input() timelineData: {
    _id: string;
    name: string;
    createdAt: string
    toolTipText?: string
  }[];
  @Input() selectedId: string;
  @Output() clickedDataId: EventEmitter<string> = new EventEmitter<string>();

  MISSION_CREATION_DATE_FORMAT = MISSION_CREATION_DATE_FORMAT

  handleInstance(_id: string) {
    this.clickedDataId.emit(_id);
  }

  onWheelScrollMissionList(event: WheelEvent) {
    // Prevent default behavior to stop the page from scrolling vertically
    event.preventDefault();

    // Calculate the amount to scroll horizontally
    const scrollAmount = event.deltaY;

    // Get the scroll container element
    const container = event.currentTarget as HTMLElement;

    // Adjust the scroll position of the container
    container.scrollLeft += scrollAmount;
  }
}
