<div class="timeline-container" (wheel)="onWheelScrollMissionList($event)">
  @for (instance of timelineData; track instance._id) {
    <div class="timeline-continuity"></div>
    <div
      class="timeline-instance"
      data-bs-placement="right"
      data-bs-toggle="timeline-tooltip"
      data-bs-custom-class="custom-tooltip"
      [title]="instance?.toolTipText"
      [ngClass]="{ filled: instance._id === selectedId }"
      (click)="handleInstance(instance._id)"
    ></div>
    <div class="timeline-continuity"></div>
  }
</div>
