<div class="toaster position-fixed top-0 end-0 px-3 py-2 m-16">
  @for (toast of currentToasts; track toast; let i = $index) {
    <div>      
      <app-custom-notification
        [type]="toast.type"
        [title]="toast?.title"
        [message]="toast.message"
        (disposeEvent)="dispose(i)"
      ></app-custom-notification>
    </div>
  }
</div>