import { effect, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IInventoryRecord } from '../../../interfaces/api-response/inventory-api';
import { ApiService } from '../api/api.service';
import { STORE_DATA_KEY } from '../../../constants/store.constant';
import { INVENTORY_TYPE } from '../../../constants/components/asset-navigation';
import { AssetSignalStore } from './asset-signal-store.service';

@Injectable({
  providedIn: 'root',
})
export class AssetService {

  searchTermSubject = new BehaviorSubject<string | undefined>(undefined);
  searchTerm$ = this.searchTermSubject.asObservable();

  searching = new BehaviorSubject<boolean>(false);
  searching$ = this.searching.asObservable();

  assetSource = new BehaviorSubject<IInventoryRecord | null>(null);
  currentAsset$ = this.assetSource.asObservable();

  deletedAssetSource = new BehaviorSubject<IInventoryRecord | null>(null);
  deletedAsset$ = this.deletedAssetSource.asObservable();

  private loadingMissionList = new BehaviorSubject<boolean>(false);
  loadingAssetMission$ = this.loadingMissionList.asObservable();

  constructor(
    private apiService: ApiService,
    private assetSignalStore: AssetSignalStore,
  ) {
    //get mission by selected asset
    effect(() => {
      this.getMissionByAsset();
    });
  }

  private getMissionByAsset = async () => {
    const hierarchyNode = this.assetSignalStore.select(
      STORE_DATA_KEY.SELECTED_NODE,
    )();
    if (hierarchyNode?.type === INVENTORY_TYPE.ASSET) {
      if (!hierarchyNode?.id) return;
      this.loadingMissionList.next(true);
      const missionResp = await this.apiService
        .get('MISSION_SERVICE')
        .getMissions({
          assetIds: [hierarchyNode?.id],
        });
      const filteredMissions = missionResp?.records.filter(
        ({ status }: { status: string }) => status !== 'DRAFT',
      );
      filteredMissions.sort((missionObjA: any, missionObjB: any) => {
        const dateA = new Date(missionObjA.createdAt).getTime();
        const dateB = new Date(missionObjB.createdAt).getTime();
        return dateA - dateB;
      });
      this.assetSignalStore.set(
        STORE_DATA_KEY.SELECTED_ASSET_MISSION_LIST,
        filteredMissions,
      );
      this.loadingMissionList.next(false);
    }
  };

  updateAssetInHierarchy(asset: IInventoryRecord) {
    this.assetSource.next(asset);
    const prevAssetData = this.assetSignalStore.select(STORE_DATA_KEY.ASSETS)();
    const updatedAsset =
      prevAssetData?.find(
        (prevAsset: IInventoryRecord) => prevAsset?._id === asset?._id,
      ) ?? {};
    Object.assign(updatedAsset, asset);
    this.assetSignalStore.set(STORE_DATA_KEY.ASSETS, prevAssetData);
  }

  deleteAssetFromHierarchy(asset: IInventoryRecord) {
    this.deletedAssetSource.next(asset);
  }
}
