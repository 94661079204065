import { EnvironmentService } from 'projects/shared-library/src/lib/services/environment.service'

export const EMPTY_ASSET_MSG = 'No assets yet. Create one now.'
export const LIST_TXT = 'List'
export const ADD_TXT = 'Add'
export const ASSETS_TXT = 'Assets'
export const FEATURE_TXT = 'Feature'
export const FEATURECOLLECTION_TXT = 'FeatureCollection'
export const POINT_TXT = 'Point'
export const POLYGON_TXT = 'Polygon'
export const LINE_TXT = 'line'
export const LINESTRING_TXT = 'LineString'
export const GEOJSON_TXT = 'geojson'
export const POLYGON_SUFFIX_TXT = '0'
export const MARKER_CUSTOM_TXT = 'marker-custom'
export const SYMBOL_TXT = 'symbol'
export const FILL_TXT = 'fill'
export const MB_MAX_ZOOM = 23
export const LIMIT_MAX_ZOOM = 17
export const MB_PADDING = 18

export const ICON_ADD = '/icons/add.svg'
export const ICON_CHEVRON = {
  right: '/icons/chevron-right.svg',
  left: '/icons/chevron-left.svg'
}
export const ICON_MARKER_CUSTOM = '/maki-icons/marker-custom.svg'
export const DRONE_ICON_MARKER = '/maki-icons/drone-icon.png'

export const HIERARCHY_MAP_SOURCES = {
  levelGeojson: 'level-geojson',
  assetPoint: 'asset-point'
}

export const HIERARCHY_MAP_LAYERS = {
  polygonSuffix: '-polygon-layer',
  lineSuffix: '-line-layer',
  outlineSuffix: '-outline',
  fillColor: '#0ff0ff',
  fillOpacity: 0.3,
  lineWidth: 2,
  iconSize: 1,
  iconAllowOverlap: true,
  iconOffset: [0, -12]
}

export const MAP_LAYERS = {
  polygonLayer: "polygon",
  outlineLayer: "outline",
  lineStringLayer: "lineString",
  clusteredImagePointersLayer: "clusteredImagePointers",
  clusteredImagePointersShadowLayer: "clusteredImagePointersShadow",
  clusterCountLayer: "clusterCount",
  imagePointersLayer: "imagePointers"
};

export const EVENT_LISTENERS = {
  mapLoaded: 'dronos:utm:mapbox-loaded'
}
const environment = new EnvironmentService()
export const BASE_API_URL = environment.getApiUrl()
export const BASE_API_SITE_HIERARCHY = `${BASE_API_URL}/hierarchy-structure`
export const BASE_API_INVENTORY = `${BASE_API_URL}/inventory`
export const BASE_API_TEMPLATE = `${BASE_API_URL}/templates`

export const API_PAGE_LIMIT = 500;

export const DEBOUNCE_TIME = 500;

export const ASSET_INFO_KEYS_NON_TEMPLATE = ['assetName', 'assetStatus', 'assetType', 'assetLatitude', 'assetLongitude']

export const ASSET_HIERARCHY_DASHBOARD_EVENTS = {
  ASSET_HIERARCHY_CLICK: 'dronos:asset-hierarchy-click',
  SHOW_DASHBOARD: 'dronos:show-asset-hierarchy-dashboard'
}

export const DASHBOARD_RIGTH_PANEL_TOP = {
  ON_HIERARCHY_CLICK: '110px',
  ON_ASSET_CLICK: '340px'
}

export const DASHBOARD_RIGHT_PANEL_COMPONENTS = {
  header: true,
  filters: true,
  findingsTotal: true,
  findingByPriority: true,
  findingType: true,
  findingsByComponent: true,
  findingsByMonth: true,
  findingsByLaterals: true,
  findingsByRegionalOffice: true,
};