import { EnvironmentService } from 'projects/shared-library/src/lib/services/environment.service';

const environment = new EnvironmentService()
export const BASE_API_URL = environment.getApiUrl();
export const BASE_API_SITE_HIERARCHY = `${BASE_API_URL}/hierarchy-structure`;
export const BASE_API_INVENTORY = `${BASE_API_URL}/inventory`;
export const BASE_API_CANVAS = `${BASE_API_URL}/canvas`
export const BASE_API_MISSIONS = `${BASE_API_URL}/mission`
export const BASE_API_DATA_RESOURCE = `${BASE_API_URL}/data-resource`

export const INVENTORY_API_ROUTES = {
  ASSETS: `${BASE_API_INVENTORY}/assets`,
} as const

export const HIERARCHY_STRUCTURE_API_ROUTES = {
  HIERARCHY_STRUCTURE: `${BASE_API_SITE_HIERARCHY}/hierarchy-structure`
} as const

export const CANVAS_API_ROUTES = {
  INSPECTION_FINDINGS: `${BASE_API_CANVAS}/inspection-findings`
} as const;

export const MISSIONS_API_ROUTES = {
  MISSIONS: `${BASE_API_MISSIONS}/missions`,
  DATA_PROC_ONLY: `${BASE_API_MISSIONS}/data-proc-only/missions`
}

export const INVENTORY = 'INVENTORY'

export const DATA_RESOURCE_API_ROUTES = {
  DATA_RESOURCE_STORAGE: `${BASE_API_DATA_RESOURCE}/storage`,
  DATA_RESOURCE_LIST_BY_IDS: `${BASE_API_DATA_RESOURCE}/data-resource/list-by-ids`,
  DATA_RESOURCE: `${BASE_API_DATA_RESOURCE}/data-resource`
}