import { Component, Input, SimpleChanges } from '@angular/core';
import { Subject, debounceTime, takeUntil } from 'rxjs';
import { SVG_ICONS } from 'projects/hierarchy-structure/src/app/constants/icon';
import { INVENTORY_TYPE } from '../../../constants/components/asset-navigation';
import { API_PAGE_LIMIT, DEBOUNCE_TIME, EMPTY_ASSET_MSG, FEATURECOLLECTION_TXT, HIERARCHY_MAP_SOURCES } from '../../../constants/hierarchy.constant';
import { INestedAccordionData } from '../../../interfaces/components/nested-accordion';
import { IGetAssetsParams, IInventoryAPIResponse, IInventoryRecord } from '../../../interfaces/api-response/inventory-api';
import { HierarchyLookUp } from '../../../interfaces/api-response/hierarchy-api';
import { AssetMap, IFeatures } from '../../../interfaces/components/asset-details';

import { HierarchyMapService } from '../../../services/hierarchy-map.service';
import { AssetSignalStore } from '../../shared-services/store/asset-signal-store.service';
import { HierarchyAnalyticsService } from '../../../services/hierarchy-analytics.service';

import { cloneDeep, isNumber } from 'lodash';
import { STORE_DATA_KEY } from '../../../constants/store.constant';
import { AssetService } from '../../shared-services/store/asset.service';
import { HierarchyUtilService } from '../../../services/hierarchy-util.service';

@Component({
  selector: 'app-nested-accordion',
  templateUrl: './nested-accordion.component.html',
  styleUrl: './nested-accordion.component.scss',
})
export class NestedAccordionComponent {
  @Input() hierarchyLookUp: HierarchyLookUp;
  @Input() assetsByHierarchyId: AssetMap;
  @Input() nestedAccordionData: INestedAccordionData[];
  @Input() getAssetData: (params: IGetAssetsParams) => Promise<any>;

  originalAccordionData: INestedAccordionData[];
  accordionData: INestedAccordionData[];
  hierarchyLookUpCopy: HierarchyLookUp;

  emptyAssetMsg = EMPTY_ASSET_MSG;
  featureCollectionTxt = FEATURECOLLECTION_TXT;
  hierarchyMapSources = HIERARCHY_MAP_SOURCES;
  SVG_ICONS = SVG_ICONS;

  searchTerm: string;
  dataLoading: false;

  private destroy$ = new Subject<void>();

  constructor(
    private hierarchyMapService: HierarchyMapService,
    private assetSignalStore: AssetSignalStore,
    private assetService: AssetService,
    private hierarchyAnalyticsService: HierarchyAnalyticsService,
    private hierarchyUtilService: HierarchyUtilService
  ) {
    this.subscribeToNodeToExpand();
    this.subscribeToSearchTerm();
    this.subscribeToDeletedAsset();
    this.subscribeToCurrentAsset();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['nestedAccordionData']) {
      this.accordionData = changes['nestedAccordionData'].currentValue
      this.originalAccordionData = changes['nestedAccordionData'].currentValue;
      this.hierarchyLookUp = this.createHierarchyLookup(this.originalAccordionData);
    }
  }

  updateAssetInHierarchy(asset: IInventoryRecord) {
    const parentNode = this.hierarchyLookUp[asset.hierarchyId];

    if (parentNode?.children) {
      const matchingChild = parentNode.children.find(child => child._id === asset._id);

      if (matchingChild) {
        const formatedAsset = this.formatAssetDetailsForHierarchy(asset)
        Object.assign(matchingChild, formatedAsset)
        const features: IFeatures[] = [] 
        parentNode.children?.forEach((assetItem: INestedAccordionData) => {
          const [assetLongitude, assetLatitude] = assetItem.location?.coordinates ?? [];
          if (isNumber(assetLongitude) && isNumber(assetLatitude)) {
            features.push(this.hierarchyMapService.formatToPointFeature(assetLongitude, assetLatitude, assetItem))
          }
        })

        const zoomIn = false;
        const addSource = false;
        this.hierarchyMapService.addAndZoomMarker(this.hierarchyMapSources.assetPoint, features, zoomIn, addSource)
      }
    }
  }

  deleteAssetFromHierarchy(asset: IInventoryRecord) {
    const parentNode = this.hierarchyLookUp[asset.hierarchyId];

    if (parentNode?.children) {
      const matchingChildIndex = parentNode.children.findIndex(child => child._id === asset._id);

      if (matchingChildIndex !== -1) {
        parentNode.children.splice(matchingChildIndex, 1);
      }
    }
  }

  isAssetNode(node: INestedAccordionData) {
    return node.type === INVENTORY_TYPE.ASSET;
  }

  toggleAllParents(accordionEntry: INestedAccordionData) {
    let currentEntry = accordionEntry;

    while (currentEntry) {
      currentEntry.expanded = true;
      currentEntry = this.hierarchyLookUp[currentEntry.parentId!];
    }
  }

  toggleExpansion(accordionEntry: INestedAccordionData) {
    accordionEntry.expanded = !accordionEntry.expanded;
  }

  handleSelection(accordionEntry: INestedAccordionData) {
    if (accordionEntry?.location?.features) {
      this.hierarchyMapService.zoomToLocation(accordionEntry?.location?.features)
    }

    // SETTING Selected Node in Store
    this.setSelectedNode(accordionEntry)

    this.toggleExpansion(accordionEntry);
    this.fetchAssets(accordionEntry);
  }

  setSelectedNode(accordionEntry: INestedAccordionData) {
    const selectedNode = this.assetSignalStore.select(STORE_DATA_KEY.SELECTED_NODE)()
    if (accordionEntry?.type === INVENTORY_TYPE.ASSET && accordionEntry?.hierarchyId !== selectedNode?.hierarchyId) {
      this.assetSignalStore.setState({
        [STORE_DATA_KEY.SELECTED_NODE]: {
          id: accordionEntry._id,
          name: accordionEntry?.text ?? '',
          type: accordionEntry.type,
          hierarchyId: accordionEntry.hierarchyId ?? ''
        },
        [STORE_DATA_KEY.ASSETS]: this.assetsByHierarchyId.get(accordionEntry.hierarchyId!) ?? []
      })
    } else {
      this.assetSignalStore.set(STORE_DATA_KEY.SELECTED_NODE, {
        id: accordionEntry._id,
        name: accordionEntry?.text ?? '',
        type: accordionEntry.type,
        hierarchyId: accordionEntry.hierarchyId ?? ''
      });
    }
  }

  private subscribeToNodeToExpand(): void {
    this.hierarchyMapService.nodeToExpand$
      .pipe(takeUntil(this.destroy$))
      .subscribe((node) => {
        this.handleNodeExpansion(node);
      });
  }

  private handleNodeExpansion(node: INestedAccordionData): void {
    const parentNode: INestedAccordionData = this.hierarchyLookUp[node.hierarchyId!];

    if (this.assetsByHierarchyId.has(node.hierarchyId!)) {
      const assets = this.assetsByHierarchyId.get(node.hierarchyId!)
      parentNode.children = assets?.map(this.formatAssetDetailsForHierarchy);
    }

    if (parentNode) {
      this.toggleAllParents(parentNode);
      this.hierarchyUtilService.scrollToElement(node.hierarchyId!)

      // SETTING Selected Node in Store
      this.assetSignalStore.set(STORE_DATA_KEY.SELECTED_NODE, {
        id: node._id,
        name: node?.text ?? '',
        type: INVENTORY_TYPE.ASSET,
        hierarchyId: node.hierarchyId ?? '',
      });
    }
  }

  private subscribeToSearchTerm(): void {
    this.assetService.searchTerm$
      .pipe(debounceTime(DEBOUNCE_TIME), takeUntil(this.destroy$))
      .subscribe((searchTerm) => {
        this.handleSearchTerm(searchTerm!);
      });
  }

  private handleSearchTerm(searchTerm: string): void {
    if (searchTerm === undefined) {
      return;
    }

    if (searchTerm.length >= 3) {
      this.performAPISearch(searchTerm).then(() => {
        const searchResults = this.hierarchyUtilService.performSearch(
          cloneDeep(this.originalAccordionData),
          searchTerm
        );
        this.accordionData = searchResults;
      });
    } else {
      this.accordionData = cloneDeep(this.originalAccordionData);
    }

    this.searchTerm = searchTerm;
  }

  private subscribeToDeletedAsset(): void {
    this.assetService.deletedAsset$
      .pipe(takeUntil(this.destroy$))
      .subscribe(asset => {
        this.handleDeletedAsset(asset);
      });
  }

  private handleDeletedAsset(asset: IInventoryRecord | null): void {
    if (asset) {
      this.deleteAssetFromHierarchy(asset);
    }
  }

  private subscribeToCurrentAsset(): void {
    this.assetService.currentAsset$
      .pipe(takeUntil(this.destroy$))
      .subscribe(asset => {
        this.handleCurrentAsset(asset);
      });
  }

  private handleCurrentAsset(asset: IInventoryRecord | null): void {
    if (asset) {
      this.updateAssetInHierarchy(asset);
    }
  }


  private async fetchAssets(accordionEntry: IInventoryRecord | INestedAccordionData) {
    const { _id, type, assetsFetched, isLastChild } = accordionEntry;

    if (type === INVENTORY_TYPE.ASSET) {
      this.navigateToSingleAsset([accordionEntry as IInventoryRecord])
    }

    if (type === INVENTORY_TYPE.HIERARCHY && isLastChild && assetsFetched) {
      this.navigateToMultipleAssets(accordionEntry?.children as INestedAccordionData[])
    }

    if (type === INVENTORY_TYPE.HIERARCHY && isLastChild && !assetsFetched) {
      await this.fetchAndProcessHierarchyAssets(accordionEntry);
    }

    this.setAssetListForAnalytics(accordionEntry)
  }

  private async fetchAndProcessHierarchyAssets(accordionEntry: INestedAccordionData | IInventoryRecord) {
    const { _id } = accordionEntry;
    let nextCursor: string | undefined;
    let allFetchedChildren: IInventoryRecord[] = [];

    accordionEntry.loading = true;
    this.hierarchyAnalyticsService.loadingAssets.next(true)

    while (true) {
      const params = {
        hierarchyId: _id,
        pageLimit: API_PAGE_LIMIT,
        showSummary: true,
        ...(nextCursor && { nextCursor: nextCursor }),
      };

      let assetResponse: IInventoryAPIResponse;
      assetResponse = await this.getAssetData(params);

      allFetchedChildren = [...allFetchedChildren, ...assetResponse.records];
      nextCursor = assetResponse?.meta?.nextCursor!;

      if (!nextCursor) {
        break;
      }
    }

    this.hierarchyAnalyticsService.loadingAssets.next(false)
    accordionEntry.loading = false;

    this.processFetchedChildren(accordionEntry, allFetchedChildren);
  }

  private processFetchedChildren(accordionEntry: INestedAccordionData | IInventoryRecord, allFetchedChildren: IInventoryRecord[]) {
    let mappedChildren: INestedAccordionData[] = [];

    if (allFetchedChildren) {
      mappedChildren = allFetchedChildren.map(this.formatAssetDetailsForHierarchy);
    }

    accordionEntry.children = mappedChildren;
    accordionEntry.uniqueAssetIds = new Set<string>(mappedChildren.map(asset => asset._id));

    const features: IFeatures[] = []; 
    mappedChildren.forEach((asset: INestedAccordionData) => {
      if (isNumber(asset.assetLongitude) && isNumber(asset.assetLatitude)) {
        features.push(this.hierarchyMapService.formatToPointFeature(asset.assetLongitude!, asset.assetLatitude!, asset))
      }
    })

    if (features.length) {
      this.hierarchyMapService.addAndZoomMarker(this.hierarchyMapSources.assetPoint, features, true, false)
    }

    accordionEntry.assetsFetched = true;
  }

  private formatAssetDetailsForHierarchy({ _id: assetId, assetName, hierarchyId, location }: IInventoryRecord) {
    const [assetLongitude, assetLatitude] = location?.coordinates ?? []
    return {
      _id: assetId,
      text: assetName,
      type: INVENTORY_TYPE.ASSET,
      hierarchyId,
      assetLongitude,
      assetLatitude,
      location,
    }
  }

  private navigateToMultipleAssets(assetList: IInventoryRecord[] | INestedAccordionData[]) {
    this.processAssetsAndPerformAction(assetList, (features: IFeatures[]) => {
      const zoomIn = true;
      const addSource = false;
      this.hierarchyMapService.addAndZoomMarker(this.hierarchyMapSources.assetPoint, features, zoomIn, addSource);
    });
  }

  private navigateToSingleAsset(asset: IInventoryRecord[] | INestedAccordionData[] = []) {
    this.processAssetsAndPerformAction(asset, (features: IFeatures[]) => {
      this.hierarchyMapService.zoomToLocation(features);
    });
  }

  private processAssetsAndPerformAction(assets: IInventoryRecord[] | INestedAccordionData[], action: (features: IFeatures[]) => void) {
    if (assets.length) {
      const features = assets.map((asset) => {
        return this.hierarchyMapService.formatToPointFeature(asset?.assetLongitude!, asset?.assetLatitude!, asset)
      });

      action(features);
    }
  }

  private createHierarchyLookup(data: INestedAccordionData[]): HierarchyLookUp {
    let lookup: { [key: string]: any } = {};

    for (let item of data) {
      lookup[item._id] = item;

      if (item.children) {
        let childLookup = this.createHierarchyLookup(item.children);
        lookup = { ...lookup, ...childLookup };
      }
    }
    return lookup;
  }

  private async performAPISearch(searchTerm: string | undefined): Promise<boolean> {
    this.assetService.searching.next(true);
    const params = {
      search: searchTerm,
      searchKeys: "",
      pageLimit: API_PAGE_LIMIT,
    };
  
    const assetResponse = await this.getAssetData(params);
    this.assetService.searching.next(false);
  
    if (assetResponse.records) {
      assetResponse.records.forEach((asset: IInventoryRecord) => {
        this.processAsset(asset);
      });
  
      return true;
    }
  
    return false;
  }

  private processAsset(asset: IInventoryRecord) {
    const { _id, assetName, hierarchyId } = asset;
    const mappedAsset = {
      _id,
      text: assetName,
      hierarchyId,
      type: INVENTORY_TYPE.ASSET,
    };

    const parentNode = this.hierarchyLookUp[hierarchyId];
    if (parentNode) {
      const existingChild = parentNode?.children?.find((child: INestedAccordionData) => child._id === mappedAsset._id);
      if (!existingChild) {
        parentNode?.children?.push(mappedAsset);
      }
    }
  }

  private setAssetListForAnalytics(accordionEntry: INestedAccordionData | IInventoryRecord) {
    if (!accordionEntry?.isLastChild && accordionEntry?.type === INVENTORY_TYPE.HIERARCHY) {
      this.hierarchyAnalyticsService.hierarchySelect(accordionEntry?._id)
    }
    if (accordionEntry?.type === INVENTORY_TYPE.HIERARCHY && accordionEntry?.isLastChild) {
      this.hierarchyAnalyticsService.setAssetListIds((accordionEntry?.children as INestedAccordionData[])?.map((asset: INestedAccordionData) => asset._id) ?? [])
    }
    if (accordionEntry?.type === INVENTORY_TYPE.ASSET) {
      this.hierarchyAnalyticsService.setAssetListIds([accordionEntry?._id])
    }
  }
  
}

