<div
  #toastElement
  class="toast toaster-container fade toast-width mt-2 w-100"
  [ngClass]="type"
  role="alert"
  aria-live="assertive"
  aria-atomic="true"
>
  <div class="custom-msg-container">
      <div class="msg-icon">
        <span class="badge bg-{{ type }}-icon">
          @switch (type) {
            @case (eventTypes.success) {
              <i class="fa fa-check"></i>
            }
            @case (eventTypes.info) {
              <i class="fa fa-info"></i>
            }
            @case (eventTypes.warning) {
              <i class="fa fa-warning fa-2x"></i>
            }
            @case (eventTypes.error) {
              <i class="fa fa-remove fa-2x"></i>
            }
            @case (eventTypes.primary) {
              <i class="fa fa-info-circle"></i>
            }
            @case (eventTypes.sticky) {
              <i class="fa fa-info"></i>
            }
          }
        </span>     
    </div>
    <div class="msg-content mt-1">
      <span>{{ message }}</span>
    </div>
  </div>
</div>
