import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-delete-confirmation-modal',
  templateUrl: './delete-confirmation-modal.component.html',
  styleUrl: './delete-confirmation-modal.component.scss'
})
export class DeleteConfirmationModalComponent {
  displayModal = false
  displayText = ''
  @Input() deleteModalConfig = {
    height: '444px',
    width: '500px',
  };
  @Output() confirmAction: EventEmitter<boolean> = new EventEmitter();
  
  openModal(name?: string) {
    this.displayText = name ?? ''
    this.displayModal = true;
  }

  closeModal() {
    this.displayModal = false;
  }

  onConfirmClick() {
    this.confirmAction.emit(true);
  }
  onCancelClick() {
    this.confirmAction.emit(false);
  }
}
