export const THUMBNAIL_2D_VIEW_EVENTS = {
  VERTICAL_VIEW: 'vertical-view',
  HORIZONTAL_VIEW: 'horizontal-view',
  EXPAND_VIEW: 'expand-view'
}
export const API_MESSAGE_EVENTS = {
  GENERIC_DRAW_MESSAGE: 'Polygon in coordinates must contain at least 4 sides',
  GENERIC_ERR_MESSAGE: "Oops! Something went wrong",
  GENERIC_CREATE_SUCCESS_MESSAGE: "Created Successfully!...",
  GENERIC_UPDATE_SUCCESS_MESSAGE: "Updated Successfully!...",
  GENERIC_DELETE_SUCCESS_MESSAGE: "Deleted Successfully!...",
  REVIEW_UPDATE_MESSAGE: "Reviewed",
  GENERIC_ERR_IMAGE_MESSAGE: "Oops! No Data Available.",
  GENERIC_ERR_FINDING_IMAGE_MESSAGE: "Oops! No Data Available Under Findings.",
}
export const CANVAS_GEOMETRIC_TYPE = {
  POINT_TYPE: 'Point',
  LINESTRING_TYPE: 'LineString',
  POLYGON_TYPE: 'Polygon'
}
export const IRESOURCE_TAG = {
  ORIGINAL_TAG: 'orig_full_res',
  THUMBNAIL_TAG: 'thumbnail',
  COMPRESSED_FULL_RES: 'compressed_full_res',
  PAGE_LIMIT: 50,
  IMAGE_GROUP_HIERARCHY: 'image_group_hierarchy:',
  IMAGE_GROUP_HIERARCHY_OPGW: 'image_group_hierarchy:OPGW',
  THERMAL_IMAGE_RAW: 'raw_thermal_flir',
  VIDEO_TAG: 'orig_full_res',
  VIDEO_THUMBNAIL_TAG: 'video_thumbnail',
};
export const THUMB_STAGE_VERITICAL_VIEW = {
  _PREFIX: 'annotateVerticDto_',
  WIDTH: 340,
  HEIGHT: 180,
}
export const THUMB_STAGE_HORIZONTAL_VIEW = {
  _PREFIX: 'annotateHorizDto_',
  WIDTH: 170,
  HEIGHT: 140,
  THUMB_VIEW_WIDTH: 340,
  THUMB_VIEW_HEIGHT: 250
}
export const ANNOTATION_DRAWBOX_INIT_PROPERTY = {
  DRAWMODE: 'rectangle',
  DRAWBOX_NAME: 'rect',
  POLY_NAME: 'poly',
  POLY_NAME_TEMP: 'polyTemp',
  DRAWBOX_X: 100,
  DRAWBOX_Y: 40,
  DRAWBOX_WIDTH: 325,
  DRAWBOX_HEIGHT: 350,
  DRAWBOX_FILL: 'rgba(0, 240, 255, 0.08)',
  DRAWBOX_STROKE: '#00F0FF',
  DRAWBOX_STROKEWIDTH: 1,
  DRAWBOX_STROKEWIDTH_T2: 0.8,
  DRAWBOX_STROKEWIDTH_CLK: 10,
  DRAWBOX_STROKEWIDTH_CLK_7: 7,
  DRAWBOX_STROKEWIDTH_CLK_5: 5,
  DRAWBOX_STROKEWIDTH_CLK_2: 2,
  DRAWBOX_POLY_STROKEWIDTH_VIEW: 10,
  DRAWBOX_POLY_STROKEWIDTH_THUMB: 1,
  DRAWBOX_SHADOWBLUR: 1,
  DRAWBOX_OPACITY: 0,
  DRAWBOX_OPACITY_INIT: 1,
  DRAWBOX_FADE_IN_OPACITY: 1,
  DRAWBOX_FADE_IN_DURATION_T1: 1,
  DRAWBOX_FADE_IN_DURATION: 2,
  RADIUS: 25,
  CIRCLE_PRIMARY: '#00F0FF',
  CIRCLE_DRAW_PREFIX: 'circle_',
  PROGRESSIVE_TRANSDURATION: 1000,
  PROGRESSIVE_FRAMERATE: 10,
  PROGRESSIVE_FRAME: 0,
  PROGRESSIVE_BLURRADIUS: 50,
  PROGRESSIVE_PERCENTAGE: 100,
  PROGRESSIVE_BLUR_RADIUS_L1: 0,
  PROGRESSIVE_BLUR_RADIUS_L2: 2,
  PROGRESSIVE_BLUR_RADIUS_L3: 50,
  PROGRESSIVE_BLUR_STAGE_WID: 450,
  PROGRESSIVE_BLUR_AJST_HEIGHT: 30,
  DRAWBOX_STROKE_RED: '#FF0000',
  DRAWBOX_STROKE_BLACK: '#000000',
  DRAWBOX_FILL_RED: 'rgba(182, 20, 20, 0.12)',
  CIRLCE_FILL_RED: '#FF0000',
}
export const STAGE_2D_CANVAS_COMMON_VIEW = {
  THUMB_STAGENAME: 'thumbnailStage',
  ANNOTATION_TYPE: 'annotation_2d',
  ANNOTATION_TAG: 'annotation',
  VALIDATE_TAG: 'validated',
  MANUAL_TAG: 'manual',
  INVALIDATE_TAG: 'invalidate',
  AI_TAG: 'ai',
  PAGE_LIMIT: 10,
  RANGE_VALUE: 50,
  ZOOM_IN_OUT_SCALE_BY: 1.1,
  ZOOM_IN_SCALE_BY: 1.2,
  ZOOM_IN_SCALE_BY_T1: 0.1,
  ZOOM_OUT_SCALE_BY: 0.9,
  ZOOM_LEVEL_FACTOR: 1,
  ZOOM_LEVEL_INIT: 1,
  ZOOM_LEVEL_TWIN_THUMB_INIT: 0.5,
  ZOOM_IN_MAX_LEVEL: 20,
  BG_FADE_IN_OPACITY: 1,
  BG_FADE_IN_DURATION_T1: 1,
  BG_FADE_IN_DURATION: 2,
  CONFIRM_BOX_ALERT: 'Are you sure you want to remove the box!',
  IMAGE_PAGE_LIMIT: 200,
  IMAGE_INDEX_KEY: 1,
  ANNOTATION_PAYLOAD_ADD: 'add',
  ANNOTATION_PAYLOAD_UPDATE: 'update',
  ANNOTATION_PAYLOAD_REMOVE: 'remove',
  CANVAS_MINIMIZE: 'minimize',
  CANVAS_MAXMIZE: 'maximize',
  CANVAS_CONTAINER: 'canvas-container',
  CANVAS_MSG_TITLE: 'Annotation canvas',
  CANVAS_COLLECTIONID: 'grid-gt-annotations',
  CANVAS_COLLECTION_TELCO: 'telco-equip-annotations',
  DISK_INSULATOR: 'disk_insulator',
  BROKEN_INSULATOR: 'broken_insulator',
  ARCHING_HORN: 'arching_horn_misaligned',
  ANTENNA: 'antenna',
  TRANSCEIVER: 'Transceiver',
  HEAD_FRAME_MOUNT: 'Head frame Mount',
  AVIATION_LIGHT: 'Aviation Light',
  SHELTER: 'Shelter',
  CURSOR_POINTER: 'pointer',
  CURSOR_MOVE: 'move',
  CURSOR_DEFAULT: 'default',
  CURSOR_CROSS: 'crosshair',
  CURSOR_RESIZE_LEFT_RIGHT: 'ew-resize',
  CURSOR_RESIZE_TOP_BOTTOM: 'ns-resize',
  CURSOR_RESIZE_TOPLEFT_BOTTOMRIGHT: 'nwse-resize',
  CURSOR_RESIZE_TOPRIGHT_BOTTOMLEFT: 'nesw-resize',
  CLIENT_RECT_DIVIDER: 2,
  CLIENT_RECT_DEGREE: 180,
  DRAW_RECT: 'Rect',
  DRAW_LINE: 'Line',
  IMAGE_LOADING: 'Canvas image is loading..',
  HORZ_RECT_THUMB_ADJUST: 2,
  VIEWER_LOADER_BLOCK: 'block',
  VIEWER_LOADER_NONE: 'none',
  IMAGE_PER_CHUNK: 10,
  POLYGON_REQUIRED_LENGTH: 6,
  SET_BGIMAGE_ZOOM_OUT: 0.1,
  SET_THUMB_BGIMAGE_ZOOM_FACTOR: 1.8, //old:2
  SET_MAIN_BGIMAGE_ZOOM_FACTOR: 0.16, //0.15,
  SET_MAIN_BGIMAGE_ZOOM_FACTOR_SMALL: 0.20, //0.18,
  DEL_CONFIRM_CONTENT: 'Are you sure you want to delete this?',
  DEL_CONFIRM_CONTENT_SNAP: 'Are you sure you want to delete this snapshot image?',
  DEL_CONFIRM_TITLE: 'Delete Confirmation',
  DEL_CONFIRM_DEL_BTN: 'Delete',
  DEL_CONFIRM_CANCEL_BTN: 'Cancel',
  TAG_VERTICAL_VIEW: 'vertical',
  TAG_HORIZONTAL_VIEW: 'horizontal',
  GROUP_TAG_NAME: 'displayTag',
  CANVAS_VIEWER_REF: 'viewer_canvas_',
  CANVAS_DRAGGABLE_MIN: 40,
  CANVAS_DRAGGABLE_MAX: 70,
  DRAG_STAGE_WIDTH: 1024,
  DEFAULT_TEXT_LABEL_FONT_SIZE: 60,
  FINDING_HEIGHT_INIT: '0px',
  FINDING_HEIGHT_ACTUAL: '150px',
  AVOID_INIT_SCROLL: 3,
  BLOB: 'blob',
  LARGE_IMAGESIZE: 5000,
  RADIUS_VALUE: 200,
  EARTH_METER: 6371000,
  SCENE_HEIGHT_THUBNAIL: '270px',
}
export const CANVAS_INPUT_CHANGE_PROPERTY = {
  DATA_COORIDINATES: 'dataCooridnates',
  SELECTED_ITEM: 'selectedItem',
  THUMBNAIL_VIEW: 'thumbnailView',
  CANVAS_QUERY: 'canvasQueryParamsObject',
  SETBACKGROUND_SRC: 'setBackgroundImageSrc',
  STAGE_PROPERTY: 'stageProperty',
  ACTIVE_SCENE_ANNOTATE_IDS:'activeSceneAnnotateIds'
}
export const CANVAS_ANNOTATION_CONTROLLER_TYPES = {
  TRANSLATE: 'translate',
  ROTATE: 'rotate',
  SCALE: 'scale',
}
export const CANVAS_NOTIFY_MESSAGES = {
  NO_COMPONENT_FOUND: 'Oops!, selected component not found.',
  ANNOTATION_NOT_FOUND: 'Annotation image not found',
}
export const ARROW_KEYS = {
  ARROW_LEFT: 'ArrowLeft',
  ARROW_RIGHT: 'ArrowRight',
  ARROW_UP: 'ArrowUp',
  ARROW_DOWN: 'ArrowDown',
}
export const VISUAL_FILTER_ITEMS = {
  SHOW_ALL: 'Show All',
}
export const GALLERY_TYPE = {
  GALLERY_CLICKABLE: 'imageGallery',
  FINDING_CLICKABLE: 'findingLabel',
}
export const SPLIT_WINDOW = {
  ACTUAL_WIDTH: 0,
  ADJUSTSCROLL_OFFSET: 3,
  WINDOW_AS_DIVIDE: 2,
  LOAD_SCALE_VALUE: 1.25
}
export const KEYBOARD_PROPERTY = {
  KEY_CODE_DELETE: 46,
  KEY_CODE_BACKSPACE : 8,
  KEY_NAME_BACKSPACE: 'Backspace',
}
export const POINTER_POSITION_DRAWBOX = { 
  TOP: 'top',
  BOTTOM: 'bottom',
  LEFT: 'left',
  RIGHT: 'right',
  TOP_LEFT: 'top-left',
  TOP_RIGHT: 'top-right',
  BOTTOM_LEFT: 'bottom-left',
  BOTTOM_RIGHT: 'bottom-right',
  NONE: '',
}
export const CLICK_THRESHOLD = 20
export const PARAMETER_CODENAME = { 
  EMISSIVITY: 'emissivity',
  REFLECTED_TEMP: 'reflectedTemp',
  OBJECT_DISTANCE: 'objectDistance',
  ATMTEMP: 'atmTemp',
  RELATIVE_HUMIDITY: 'relativeHumidity',
}
export const PAGE_LIMIT = {
  MAX: 500,
  IMAGE: 200,
  DEFAULT: 10,
}

export const SHARE_PROPERTIES = {
  MODULE_TYPE_TWIN: 'digital-twin',
  MODULE_TYPE_GRID: 'annotation-2d',
  MODE_ACTION_SPLIT_VIEWER: 'split-screen-viewer'
}

export const TRANSFORM = {
  TOP_LEFT: 'top-left', 
  TOP_RIGHT: 'top-right', 
  BOTTOM_LEFT: 'bottom-left', 
  BOTTOM_RIGHT: 'bottom-right', 
  MIDDLE_LEFT: 'middle-left', 
  MIDDLE_RIGHT: 'middle-right', 
  TOP_CENTER: 'top-center', 
  BOTTOM_CENTER: 'bottom-center'
}

export const AMS_PUBLISH_DIALOG_CONFIG = {
  TITLE: 'AMS Publish Confirmation',
  MESSAGE: 'Are you sure you want to publish this site?',
  CONFIRM_BTN_TEXT: 'Confirm',
  CANCEL_BTN_TEXT: 'Cancel'
}