export const MAPBOX_CONFIG = {
  MAP_PADDING: {
    ZOOM_CONTRACTED: { top: 0, bottom: 0, left: 0, right: 0 },
    ZOOM_EXPANDED: { top: 0, bottom: 0, left: 0, right: 0 },
    DEFAULT: { top: 200, bottom: 200, left: 150, right: 700 },
    FIT_BOUNDS: 30,
  },
  ZOOM: {
    INITIAL: 8,
    MIN: 2,
    MAX: 25,
    ASSET: 17,
    IMAGE_POINTER: 21,
    MAX_CLUSTER: 17,
  },
  COLOR: {
    TEXT: '#FFFFFF',
    IMAGE_POINTER: '#FF4646',
    CLUSTER: '#00f0ff',
    CLUSTER_TEXT: '#000000',
    CLUSTER_BORDER: '#3e7087',
  },
  ZOOM_SPEED: 1,
  CLUSTER_LAYER: {
    BASE_MULTIPLIER: 1.75, //altering this value controls the dynamic change in radius of cluster
    ZOOM_START: 12,
    ZOOM_END: 17,
    ZOOM_START_RADIUS: 15,
    ZOOM_END_RADIUS: 25,
    EXPANDED_ZOOM_END_RADIUS: 35,
    TEXT_SIZE: 14,
  },
  IMAGE_POINTER_LAYER: {
    MIN_ICON_SIZE: 0.07,
    MAX_ICON_SIZE: 0.1,
  },
  INTERPOLATION_TYPE: {
    LINEAR: 'linear',
    EXPONENTIAL: 'exponential',
  },
  INTERPOLATOIN_MULTIPLIER: 1.25,
}