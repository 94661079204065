import { ITemplateInputFieldTypes } from "../../interfaces/api-response/template-api";

export enum INVENTORY_TYPE {
  HIERARCHY = 'hierarchy',
  ASSET = 'asset',
}

export const COMPONENT_INPUT_FIELD_TYPES: ITemplateInputFieldTypes = {
  string: 'text',
  number: 'number'
}

export const ASSET_LABEL = 'Asset'

export const SELECTED_ASSET_INPUT_KEY = 'selectedAsset';

export const SEARCH_PLACEHOLDER = 'Type at least 3 letters to search...';

export const SELECTED_NODE_INTIAL_VALUES = {
  id: '',
  name: '',
  type: '',
  hierarchyId: '',
}

export const HIERARCHY_LAST_NODE_PLACEHOLDER = 'last_node'