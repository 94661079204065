export enum EventTypes {
  success = 'success',
  info = 'info',
  warning = 'warning',
  error = 'error',
  primary = 'primary',
  sticky = 'sticky',
}
export interface ToastEvent {
  type: EventTypes;
  title?: string;
  message: string;
}
