import { Injectable } from '@angular/core';
import { INestedAccordionData } from '../interfaces/components/nested-accordion';

@Injectable({
  providedIn: 'root',
})
export class HierarchyUtilService {
  scrollToElement(id: string): void {
    const elementId = `entry-${id}`;
    const element = document.getElementById(elementId);

    if (element) {
      this.performSmoothScroll(element);
    }
  }

  private performSmoothScroll(element: HTMLElement): void {
    setTimeout(() => {
      const scrollOptions: ScrollIntoViewOptions = {
        behavior: 'smooth',
        block: 'start',
      };
      element.scrollIntoView(scrollOptions);
    });
  }

  performSearch(
    accordionData: INestedAccordionData[],
    query: string,
  ): INestedAccordionData[] {
    return query
      ? accordionData.reduce((accumulatedData, item) => {
          return this.processAccordionData(accumulatedData, item, query);
        }, [] as INestedAccordionData[])
      : accordionData;
  }

  private processAccordionData(
    accumulatedData: INestedAccordionData[],
    item: INestedAccordionData,
    query: string,
  ): INestedAccordionData[] {
    if (item.children?.length) {
      const filtered = this.performSearch(item.children, query);

      if (filtered.length) {
        item.expanded = true;
        return [...accumulatedData, { ...item, children: filtered }];
      }
    }

    const { children, ...itemWithoutChildren } = item;
    const match = item.text?.toLowerCase().includes(query.toLowerCase());

    if (match) {
      itemWithoutChildren.expanded = true;
      return [...accumulatedData, itemWithoutChildren];
    } else {
      return accumulatedData;
    }
  }
}
