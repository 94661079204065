import { Injectable } from '@angular/core';
import { CanvasAPI, HierarchyAPI, InventoryAPI, MissionsAPI } from './routes';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(private http: HttpClient) {}

  private APIMethods = {
    HIERARCHY_SERVICE: new HierarchyAPI(this.http),
    INVENTORY: new InventoryAPI(this.http),
    CANVAS_SERVICE: new CanvasAPI(this.http),
    MISSION_SERVICE: new MissionsAPI(this.http),
  } as const;

  get<T extends keyof typeof this.APIMethods>(type: T) {
    return this.APIMethods[type];
  }
}
