<div class="search-bar-container">
  <input class="search-bar" type="search" [placeholder]="placeHolder ?? 'Search'" (keyup)="onInputChange($event)" />
  @if (loading) {
    <div class="spinner">
      <span class="spinner-border spinner-border-sm"></span>
    </div>
  } @else {
    <img class="search-icon" [src]="SVG_ICONS.MAGNIFIER | assetUrl" alt="magnifier icon" />
  }
</div>
