import { Injectable } from '@angular/core';
import * as turf from '@turf/turf';
import { assetUrl } from 'projects/hierarchy-structure/src/single-spa/asset-url';
import { state } from '@app/utility'
import { DRONE_ICON_MARKER, EVENT_LISTENERS, FEATURECOLLECTION_TXT, FEATURE_TXT, FILL_TXT, GEOJSON_TXT, HIERARCHY_MAP_LAYERS, HIERARCHY_MAP_SOURCES, LIMIT_MAX_ZOOM, LINESTRING_TXT, LINE_TXT, MAP_LAYERS, MARKER_CUSTOM_TXT, MB_MAX_ZOOM, MB_PADDING, POINT_TXT, POLYGON_TXT, SYMBOL_TXT } from '../constants/hierarchy.constant';
import * as mapboxgl from 'mapbox-gl';
import { IFeatures } from '../interfaces/components/asset-details';
import { MAPBOX_CONFIG } from 'projects/annotation-2d/src/app/constants';
import { ReplaySubject, Subject } from 'rxjs';
import { IInventoryRecord } from '../interfaces/api-response/inventory-api';
import { INestedAccordionData } from '../interfaces/components/nested-accordion';

@Injectable({
  providedIn: 'root'
})
export class HierarchyMapService {
  droneIconMarker = DRONE_ICON_MARKER
  markerCustomTxt = MARKER_CUSTOM_TXT
  featureCollectionTxt = FEATURECOLLECTION_TXT
  featureTxt = FEATURE_TXT
  pointTxt = POINT_TXT
  polygonTxt = POLYGON_TXT
  lineStringTxt = LINESTRING_TXT
  geojsonTxt = GEOJSON_TXT
  symbolTxt = SYMBOL_TXT
  fillTxt = FILL_TXT
  lineTxt = LINE_TXT
  hierarchyMapLayers = HIERARCHY_MAP_LAYERS
  maplayers = MAP_LAYERS
  eventListeners = EVENT_LISTENERS
  hierarchyMapSources = HIERARCHY_MAP_SOURCES;
  imagesLoaded = false;

  nodeToExpand: Subject<INestedAccordionData> = new Subject<INestedAccordionData>();
  nodeToExpand$ = this.nodeToExpand.asObservable();

  mapLoaded: Subject<boolean> = new ReplaySubject<boolean>(10);
  mapLoaded$ = this.mapLoaded.asObservable();

  mapInstance: any;
  popup: any;

  constructor() {
    this.mapInstance = state.getMapbox().map;

    if (this.mapInstance === null) {
      window.addEventListener(this.eventListeners.mapLoaded, () => {
        this.initializeMap();
      }, { once: true });
    } else {
      this.initializeMap();
    }
  }

  expandNode(node: INestedAccordionData) {
    this.nodeToExpand.next(node);
  }

  resetMap() {
    Object.values(this.hierarchyMapSources).forEach(sourceId => {
      if (this.mapInstance.getSource(sourceId)) {
        this.mapInstance.removeSource(sourceId);
      }
    });
    
    Object.values(MAP_LAYERS).forEach(layerId => {
      if (this.mapInstance.getLayer(layerId)) {
        this.mapInstance.removeLayer(layerId);
      }
    });
  }

  private initializeMap(): void {
    this.mapInstance = state.getMapbox().map;
    this.resetMap();

    this.loadImageMarkerToMap()
      .then(() => {
        this.mapLoaded.next(true);
        this.addMapEventListeners();
        this.mapInstance._isInitialized = true;
      })
      .catch(console.log);
  }

  private addMapEventListeners(): void {
    this.addClickEventListener();
    this.addMouseEnterEventListener();
    this.addMouseLeaveEventListener();
  }

  private addClickEventListener(): void {
    this.mapInstance.on('click', "imagePointers", (pointer: mapboxgl.MapMouseEvent) => {
      const features = this.mapInstance.queryRenderedFeatures(pointer.point, { layers: ["imagePointers"] });
      if (features.length) {
        const feature = features[0].properties;
        this.expandNode(feature);
      }
    });
  }

  private addMouseEnterEventListener(): void {
    this.mapInstance.on('mouseenter', "imagePointers", (e: mapboxgl.MapMouseEvent) => {
      const features = this.mapInstance.queryRenderedFeatures(e.point, { layers: ['imagePointers'] });
      this.mapInstance.getCanvas().style.cursor = (features.length) ? 'pointer' : '';

      if (!features.length) {
        if (this.popup) {
          this.popup.remove();
        }
        return;
      }

      const feature = features[0];
      this.popup = new mapboxgl.Popup({ offset: 25 })
        .setLngLat(feature.geometry.coordinates)
        .setHTML(`<div class="mapboxgl-custom-popup">${feature.properties.text}</div>`)
        .addTo(this.mapInstance);
    });
  }

  private addMouseLeaveEventListener(): void {
    this.mapInstance.on('mouseleave', 'imagePointers', () => {
      this.mapInstance.getCanvas().style.cursor = '';
      if (this.popup) {
        this.popup.remove();
      }
    });
  }

  formatToPointFeature(lng: number, lat: number, asset?: INestedAccordionData | IInventoryRecord) {
    const { hierarchyId, text, _id, assetName } = asset!;
    return {
      type: this.featureTxt,
      geometry: {
        type: this.pointTxt,
        coordinates: [lng, lat]
      },
      properties: {
        hierarchyId,
        _id,
        text: text ?? assetName,
      }
    }
  }

  formatFeatureToGeoJSON(feature: turf.Feature | IFeatures[]) {
    return {
      type: this.featureCollectionTxt,
      features: feature
    }
  }

  loadLevelGeoJSON(location: string | undefined) {
    let geojson = {
      type: this.featureCollectionTxt,
      features: []
    }

    if (location !== null) {
      geojson = JSON.parse(location!)
    }

    this.addSourceGeoJSON(this.hierarchyMapSources.levelGeojson, geojson)
  }

  addAndZoomMarker(sourceName: string, features: IFeatures[], zoomIn?: boolean, addSource = true) {
    const combinedFeatures = this.getCombinedFeatures(sourceName, features, addSource);

    const combinedGeojson = {
      type: 'FeatureCollection',
      features: combinedFeatures
    };

    if (addSource) {
      this.addSourceGeoJSON(sourceName, combinedGeojson);
    }

    if (zoomIn) {
      this.zoomToFeatures(features);
    }
  }

  private getCombinedFeatures(sourceName: string, features: IFeatures[], addSource: boolean) {
    let combinedFeatures = features;

    if (addSource) {
      const existingFeatures = this.getExistingFeatures(sourceName);
      const newAssetGeojson = this.formatFeatureToGeoJSON(features);
      combinedFeatures = existingFeatures.concat(newAssetGeojson.features);
    }

    return combinedFeatures;
  }

  private getExistingFeatures(sourceName: string) {
    return this.mapInstance.getSource(sourceName)?.serialize()?.data?.features ?? [];
  }

  private zoomToFeatures(features: IFeatures[]): void {
    const maxZoom = features.length === 1 ? LIMIT_MAX_ZOOM : MB_MAX_ZOOM;
    const geojson = this.formatFeatureToGeoJSON(features);
    this.fitBoundGeoJSON(geojson, maxZoom);
  }


  addSourceGeoJSON(sourceName: string, geojson: turf.GeoJSONObject) {
    const source = this.mapInstance.getSource(sourceName);

    if (source !== undefined) {
      source.setData(geojson);
    } else {
      this.mapInstance.addSource(sourceName, {
        type: this.geojsonTxt,
        data: geojson,
        cluster: true,
        clusterMaxZoom: MAPBOX_CONFIG.ZOOM.MAX_CLUSTER,
      });
      this.addLayers(sourceName);
    }
  }

  fitBoundGeoJSON(geojsonData: turf.GeoJSONObject, maxZoom = MB_MAX_ZOOM) {
    const bounds = this.getBoundsFromGeoJSON(geojsonData);
    this.mapInstance.fitBounds(bounds, { padding: MB_PADDING, maxZoom: maxZoom })
  }

  getBoundsFromGeoJSON(geojsonData: turf.GeoJSONObject) {
    return turf.bbox(geojsonData)
  }

  addLayers(sourceName: string) {
    if (this.isSourceUsed(sourceName)) return;

    this.addPolygonLayers(sourceName);
    this.addLineStringLayers(sourceName);
    this.addClusteredImagePointersLayers(sourceName);
    this.addClusterCountLayer(sourceName);
    this.addImagePointersLayer(sourceName);
  }

  private isSourceUsed(sourceName: string): boolean {
    const layers = this.mapInstance.getStyle().layers;
    const layerUsingSource = layers.filter((layer: mapboxgl.Layer) => layer.source === sourceName);
    return layerUsingSource.length > 0;
  }

  private addPolygonLayers(sourceName: string): void {
    this.addLayer({
      id: this.maplayers.polygonLayer,
      type: this.fillTxt,
      source: sourceName,
      paint: {
        'fill-color': this.hierarchyMapLayers.fillColor,
        'fill-opacity': this.hierarchyMapLayers.fillOpacity
      },
      filter: ['==', '$type', this.polygonTxt]
    });

    this.addLayer({
      id: this.maplayers.outlineLayer,
      type: this.lineTxt,
      source: sourceName,
      paint: {
        'line-color': this.hierarchyMapLayers.fillColor,
        'line-width': this.hierarchyMapLayers.lineWidth
      },
      filter: ['==', '$type', this.polygonTxt]
    });
  }

  private addLineStringLayers(sourceName: string): void {
    this.addLayer({
      id: this.maplayers.lineStringLayer,
      type: this.lineTxt,
      source: sourceName,
      paint: {
        'line-color': this.hierarchyMapLayers.fillColor,
        'line-width': this.hierarchyMapLayers.lineWidth
      },
      filter: ['==', '$type', this.lineStringTxt]
    });
  }

  private addClusteredImagePointersLayers(sourceName: string): void {
    const paint = {
      'circle-color': MAPBOX_CONFIG.COLOR.CLUSTER_BORDER,
      'circle-opacity': 1,
      'circle-radius': {
        property: 'point_count',
        base: MAPBOX_CONFIG.CLUSTER_LAYER.BASE_MULTIPLIER,
        stops: [
          [MAPBOX_CONFIG.CLUSTER_LAYER.ZOOM_START, MAPBOX_CONFIG.CLUSTER_LAYER.ZOOM_START_RADIUS],
          [MAPBOX_CONFIG.CLUSTER_LAYER.ZOOM_END, MAPBOX_CONFIG.CLUSTER_LAYER.EXPANDED_ZOOM_END_RADIUS],
        ]
      }
    };

    this.addLayer({
      id: this.maplayers.clusteredImagePointersShadowLayer,
      type: 'circle',
      source: sourceName,
      filter: ['has', 'point_count'],
      paint: paint
    });

    paint['circle-radius'].stops = paint['circle-radius'].stops.map(stop => [stop[0], stop[1] - 5]);
    paint['circle-color'] = MAPBOX_CONFIG.COLOR.CLUSTER;

    this.addLayer({
      id: this.maplayers.clusteredImagePointersLayer,
      type: 'circle',
      source: sourceName,
      filter: ['has', 'point_count'],
      paint: paint
    });
  }

  private addClusterCountLayer(sourceName: string): void {
    this.addLayer({
      id: this.maplayers.clusterCountLayer,
      type: 'symbol',
      source: sourceName,
      filter: ['has', 'point_count'],
      layout: {
        'text-field': '{point_count_abbreviated}',
        'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
        'text-size': MAPBOX_CONFIG.CLUSTER_LAYER.TEXT_SIZE,
      },
      paint: {
        "text-color": MAPBOX_CONFIG.COLOR.CLUSTER_TEXT,
      }
    });
  }

  private addImagePointersLayer(sourceName: string): void {
    this.addLayer({
      id: this.maplayers.imagePointersLayer,
      type: 'symbol',
      source: sourceName,
      filter: ['!', ['has', 'point_count']],
      layout: {
        'icon-image': 'default-image',
        'icon-allow-overlap': true,
        'icon-size': this.hierarchyMapLayers.iconSize,
        'icon-offset': this.hierarchyMapLayers.iconOffset
      },
    });
  }

  private addLayer(layerConfig: any): void {
    this.mapInstance.addLayer(layerConfig);
  }

  zoomToLocation(features: IFeatures[]) {
    const assetGeojson = this.formatFeatureToGeoJSON(features)
    const maxZoom = features.length === 1 ? LIMIT_MAX_ZOOM : MB_MAX_ZOOM
    this.fitBoundGeoJSON(assetGeojson, maxZoom)
  }

  private loadImageMarkerToMap(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const imageUrl = assetUrl(this.droneIconMarker);
      this.mapInstance.loadImage(imageUrl, (error: Error | null, image: HTMLImageElement | undefined) => {
        if (error) {
          reject(error);
          return;
        }

        this.mapInstance.addImage('default-image', image!);
        resolve(true);
      });
    });
  }
}
