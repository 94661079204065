import { HttpClient } from '@angular/common/http';
import { API_PAGE_LIMIT } from 'projects/hierarchy-structure/src/app/constants/hierarchy.constant';
import { CANVAS_API_ROUTES } from 'projects/hierarchy-structure/src/app/constants/routes';
import { lastValueFrom } from 'rxjs';

interface CanvasApiMethodsType {
  getInspectionFindings: (params: {
    missionId?: string;
    assetId?: string;
  }) => Promise<any> | null;
}

export class CanvasAPI implements CanvasApiMethodsType {
  constructor(private http: HttpClient) {}

  getInspectionFindings(params: { missionId?: string; assetId?: string }) {
    try {
      let queryParams = '';
      queryParams += `pageLimit=${API_PAGE_LIMIT}&`;
      
      Object.keys(params).forEach((paramKey) => {
        queryParams += `${paramKey}=${
          params[paramKey as keyof typeof params]
        }&`;
      });
      
      const url = `${CANVAS_API_ROUTES.INSPECTION_FINDINGS}?${queryParams}`;
      return lastValueFrom(this.http.get<any>(url));
    } catch (error) {
      console.error('Error getting canvas inspection findings: ', error);
      return null;
    }
  }
}
