import { Component, Input } from '@angular/core';
import { SVG_ICONS } from '../../../constants/icon';
import { INestedAccordionData } from '../../../interfaces/components/nested-accordion';
import { IGetAssetsParams, IInventoryAPIResponse } from '../../../interfaces/api-response/inventory-api';
import { HierarchyLookUp } from '../../../interfaces/api-response/hierarchy-api';
import { AssetService } from '../../shared-services/store/asset.service';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrl: './search-bar.component.scss',
})
export class SearchBarComponent {
  @Input() getAssetData: (params: IGetAssetsParams) => Promise<IInventoryAPIResponse | undefined>;
  @Input() updateHierarchy: Function;
  @Input() placeHolder?: string;
  @Input() hierarchyData: INestedAccordionData[];
  @Input() hierarchyLookUp: HierarchyLookUp;

  loading: boolean = false;

  readonly SVG_ICONS = SVG_ICONS;

  constructor(private assetService: AssetService) { 
    this.assetService.searching$.subscribe((loading) => {
      this.loading = loading;
    })
  }

  onInputChange(event: Event) {
    const searchTerm = (event.target as HTMLInputElement).value;
    this.assetService.searchTermSubject.next(searchTerm);
  }
}
