@if (displayModal) {
    <div class="delete-container">
      <div
        class="delete-container-layout"
        [ngStyle]="{
          '--modal-height': deleteModalConfig.height,
          '--modal-width': deleteModalConfig.width
        }"
      >
        <div class="delete-container-layout-header">
          <div class="d-flex justify-content-between">
            <span class="delete-container-layout-header-title">
                DELETE
            </span>
            <span
              class="delete-container-layout-header-close"
              (click)="closeModal()"
            >
              <img [src]="'/icons/close.svg' | assetUrl" alt="round" />
            </span>
          </div>
          <div class="delete-container-layout-header-divider"></div>
        </div>
        <div class="delete-container-layout-content">
          <div class="text-center warning-container">
            <img class="p-2" [src]="'/icons/alert.svg' | assetUrl" alt="alert" />
            <h2 class="delete-warning-title p-1">Are you sure you want to delete this {{ displayText }} ?</h2>
            <p class="delete-warning-sub-title">You won’t be able to revert this action.</p>
            <div>
                <button class="warning-cancel-btn"(click)="onCancelClick()">Cancel</button>
                <button class="warning-delete-btn" (click)="onConfirmClick()">Delete</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-backdrop" (click)="closeModal()"></div>
  }
  